.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0; /* Evitar el desplazamiento hacia la derecha */
  z-index: 10;
}

.logo {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: #555;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links a:hover {
  color: #29b6f6;
}
