:root {
  --color-red: #e57373; /* Rojo suave */
  --color-yellow-sand: #f4e1b4; /* Amarillo arena */
  --color-sky-blue: #81d4fa; /* Azul cielo */
  --color-black: #333; /* Negro */
  --color-white: #fff; /* Blanco */
}

/* Reiniciar los márgenes y paddings globales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: var(--color-white);
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
}


button {
  background-color: var(--color-sky-blue);
  color: var(--color-white);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--color-red);
}
